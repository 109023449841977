
.one {
  padding: 40px 0 70px 120px;

  .header-tag {
    display: flex;
    align-items: center;

    .header-tag-box {
      width: 6px;
      height: 12px;
      background-color: #2821fc;
      border-radius: 3px;
    }
  }

  .one-container {
    margin-top: 30px;
  }

  .next-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 55px;
  }
}
